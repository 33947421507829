








import PageTitle from "@/components/PageTitle.vue"

import ServicesCart from "piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue"

import Vue from 'vue'
import { Component } from "vue-property-decorator"
import moment from "moment"

@Component({
  components: {
    ServicesCart,
    PageTitle,
  },
  data() {
    return {
      moment,
    }
  }
})
export default class BuyBoard extends Vue {
}
